import { Container, Box, Typography, Grid} from '@mui/material';
import crashed_rocket from '../media/crashed_rocket_bg_compressed.png';

const NoSumriseCompanies = () => {

  const crashedRocket = crashed_rocket;

  const errorTitle = "You're missing out!"

  const errorMessage = "It looks like you are not allowed access to Sumrise. If this is an error or you would like to request access,"
      + " please contact our staff at info@sumrise.be or 0485/12 34 56.";

      const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '5%',
        paddingLeft: '5%',
        paddingRight: '5%'
      };
    
      const gridItemStyle = {
        flexBasis: '50%', // 50% width for each item, side by side on larger screens
        padding: '16px',
      };
    
      return (
        <Container>
          <Grid container spacing={2} style={containerStyle}>
            <Grid item xs={12} md={6} style={{ ...gridItemStyle }}>
              <img src={crashedRocket} alt="A crashed rocket" style={{ width: '100%', height: 'auto' }} />
            </Grid>
    
            <Grid item xs={12} md={6} style={{ ...gridItemStyle }}>
              <Box style={{ padding: '20px', paddingTop: '10px' }}>
                <Typography variant="h4">{errorTitle}</Typography>
                <Typography variant="body1" style={{ marginTop: '5%'}}>{errorMessage}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      );
}

export default NoSumriseCompanies