import { useState, useEffect } from 'react';
import { Container, Autocomplete, TextField, Button, Box, Typography, Divider, Grid, Collapse, Alert} from '@mui/material';
import useAuth from '../hooks/useAuth';
import axios  from '../api/axios';

import sumrise_logo from '../media/sumrise_logo.svg';
import admisol_logo from '../media/admisol_logo.png';

const COMPANY_SELECT_URL = '/oauth2/login/grants/confirm';
const SUMRISE_APP_ID = process.env.REACT_APP_SUMRISE_APPLICATION_ID;


const CompanySelect = () => {

    const [selectedComp, setSelectedComp] = useState();
    const [selectedClient, setSelectedClient] = useState();
    const { auth } = useAuth();

    const [companies, setCompanies] = useState();
    const [scopes, setScopes] = useState([]);
    const [errorMess, setErrorMess ] = useState();

    const [showSecondAutocomplete, setShowSecondAutocomplete] = useState(false);
    const [secondCompanies, setSecondCompanies ]  = useState();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMess();
        try {
            console.log({
                'company': selectedComp,
                ...(selectedClient ? {'accountancy-client': selectedClient} : {}), // Conditional addition of the attribute
                'granted_scopes': scopes.map(x => x.scope)
                })
            const response = await axios.post(`${COMPANY_SELECT_URL}?request_id=${auth.requestId}`, 
                JSON.stringify({
                'company': selectedComp,
                ...(selectedClient ? {'accountancy-client': selectedClient} : {}), // Conditional addition of the attribute
                'granted_scopes': scopes.map(x => x.scope)
                }),
                {
                    headers: {'Content-Type' : 'application/json',
                    'Authorization': `Bearer ${auth.accessToken}`
                }
                });
            
            const redirectUrl = response.data;
            RedirectPage(redirectUrl);
        } catch (err) {
            if(err.response.status === 400){
                setErrorMess('Bad Request: something went wrong parsing method arguments. A required parameter was missing or a wrong parameter (type) was provided.')
            }else if(err.response.status === 401){
                setErrorMess('Unauthorized: something went wrong with client and/or user validation.');
            }else if(err.response.status === 404){
                setErrorMess('Not Found: could not parse request_id into a known authorization_request');
            }else if(err.response.status === 410){
                setErrorMess('Gone: authorization_request is no longer valid. It has either been closed or has expired.');
            }else if(err.response.status === 423){
                setErrorMess('Locked: user has attempted too many logins and is now temporarily locked out of account');
            }else {
                setErrorMess('Internal Server Error: something unexpected went wrong.');
            }
            console.log(err);
        }
    }

    const setCompany = (company) => {
        if(company != null){
            if(company.indirectlyAccessible != null){
                setShowSecondAutocomplete(true);
                setSelectedClient();
                setSecondCompanies(company.indirectlyAccessible);
            }else{
                setShowSecondAutocomplete(false);
            }
            setSelectedComp(company.companyId);
        }else{
            setShowSecondAutocomplete(false);
            setSelectedComp(null);
        }
    }

    const RedirectPage = (url) => {
        window.location.replace(url)
    }

    useEffect(() => {
        setCompanies(auth.companies);
        setScopes(auth.scopes);
    }, [auth])

    return ( 
        <Container component="main" maxWidth="xs">
            <Box
            sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
            }}
        >
            <img alt="Company Logo" src={auth.application.id == SUMRISE_APP_ID ? sumrise_logo : admisol_logo} style={{maxWidth: '100%', marginBottom: '40px'}}></img>
            <Typography component="h1" variant="h5">
            Select Company
            </Typography>
            
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <Autocomplete
                required
                margin="normal"
                sx={{width: '395px'}}
                disablePortal
                options={companies}
                getOptionLabel={(option) => option.companyName}
                renderInput={(params) => <TextField {...params} label="Company" fullWidth required name='company' />}
                onChange={(event, value) => {
                    if (value !== null) {
                        setCompany(value)
                    }else{
                        setCompany(null)
                    }
                }}
            />
            <Collapse in={showSecondAutocomplete}>
                <Autocomplete
                margin="normal"
                sx={{width: '395px', marginTop: 4}}
                disablePortal
                options={secondCompanies}
                getOptionLabel={(option) => option.companyName}
                renderInput={(params) => <TextField {...params} label="Sub company" fullWidth name='subCompany' />}
                onChange={(event, value) => {
                    if (value !== null) {
                        setSelectedClient(value.companyId)
                    }else{
                        setSelectedClient(null)
                    }
                }}
                />
            </Collapse>
            <Grid item xs={12} style={{ display: scopes.length === 0 ? 'none' : 'block' }}>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
                        <Button
                            sx={{
                                cursor: 'unset',
                                m: 2,
                                py: 0.5,
                                px: 7,
                            }}
                            disableRipple
                            disabled
                        >
                            SCOPES
                        </Button>
                        <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                {scopes.map(function(d, idx){
                    return (<li key={idx}>{d.description}</li>)
                })}
                </Grid>
            <Button
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            style={{marginLeft: '20%', marginRight: '20%', width: '60%'}}
            >
            {auth.application.id == SUMRISE_APP_ID ? 'Login' : 'Grant Access'}
            </Button>
            </Box>
            </Box>
            { errorMess ?  (<Alert severity="error">{errorMess}</Alert>) : (<></>)}
        </Container>
     );
}
 
export default CompanySelect;