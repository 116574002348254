import Login from './components/Login'
import Layout from './components/Layout'
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { CssBaseline } from '@mui/material';
import RequireAuth from './components/RequireAuth';
import CompanySelect from './components/CompanySelect';
import NoSumriseCompanies from './components/NoValidCompanies';
function App() {

  const theme = createTheme({
    palette: {
      background: {
        default: '#ffffff' //'#E0CB96'
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path ="/" element={<Layout />}>
          <Route path="auth" element={<Login />} />
          <Route element={<RequireAuth />}>
            <Route path="companySelect" element={<CompanySelect />} />
            <Route path="sumset" element={<NoSumriseCompanies />} />
          </Route>
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
