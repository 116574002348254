import { createTheme } from '@mui/material/styles';
const theme = createTheme({
  palette: {
    primary: {
      main: '#1589AF',
    },
    secondary: {
      main: '#fda909',
    },
    background: {
      default: '#FFFFFF',
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
        hover: {
          boxShadow: 'none',
        },
      },
    },
  },
});

export default theme;