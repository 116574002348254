import { useEffect, useState } from 'react';
import { Container, Alert, TextField, Button, Box, Typography, Avatar } from '@mui/material';
import { useNavigate, useLocation} from "react-router-dom";

import axios  from '../api/axios';
import useAuth from '../hooks/useAuth';

import sumrise_logo from '../media/sumrise_logo.svg';
import admisol_logo from '../media/admisol_logo.png';

const LOGIN_URL = '/oauth2/login/user';
const GET_COMPANIES = '/oauth2/login/companies'
const GET_APPLICATION = '/oauth2/login/application'
const GET_CLIENT = '/oauth2/login/client'
const GET_SCOPES = '/oauth2/login/request'

const SUMRISE_APP_ID = process.env.REACT_APP_SUMRISE_APPLICATION_ID;

const Login = () => {

    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const params = new URLSearchParams(location.search);
    const clientId = params.get("client_id");
    const requestId = params.get("request_id");

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errorMess, setErrorMess ] = useState();

    const [application, setApplication] = useState(null);
    const [logo, setLogo] = useState(null);
    const [client, setClient] = useState(null);

    const handleSubmit = async (e) => {
        setErrorMess();
        e.preventDefault();
        try {
            const loginResponse = await axios.post(`${LOGIN_URL}?request_id=${requestId}`, 
                JSON.stringify({'email': user,'password': pwd}),
                {
                    headers: {'Content-Type' : 'application/json'}
                });

            const accessToken = loginResponse?.data?.access_token;

            const getCompaniesResponse = await axios.get(`${GET_COMPANIES}?request_id=${requestId}`, {
                headers: {
                  'Authorization': `Bearer ${accessToken}`
                }
            });

            const companies = getCompaniesResponse.data;

            const getRequestedScopesResponse = await axios.get(`${GET_SCOPES}?request_id=${requestId}`, {
                headers: {
                  'Authorization': `Bearer ${accessToken}`
                }
            });

            const scopes = getRequestedScopesResponse.data;

            setAuth({user, accessToken, clientId, requestId, application, companies, scopes});
            setUser("");
            setPwd("");
            if (companies.length == 0) {
                navigate("/sumset");
                return false;
            }
            navigate("/companySelect");
        } catch (err) {
            if(err.response.status === 400){
                setErrorMess('Bad Request: something went wrong parsing method arguments. A required parameter was missing or a wrong parameter (type) was provided.')
            }else if(err.response.status === 401){
                setErrorMess('Wrong username and/or password. Please try again.');
            }else if(err.response.status === 404){
                setErrorMess('Something unexpected went wrong. Please try again.');
            }else if(err.response.status === 410){
                setErrorMess('This login attempt has expired. Please close this window and try again.');
            }else if(err.response.status === 423){
                setErrorMess('This account has been locked due to too many invalid login attempts. Please try again in 5 minutes.');
            }else {
                setErrorMess('Internal Server Error: something unexpected went wrong.');
            }
            console.log(err);
        }
    }

    useEffect(() => {
        const fetchApp = async (requestId) => {
            const getAppInfo = await axios.get(`${GET_APPLICATION}?request_id=${requestId}`);
            const application = getAppInfo.data;
            setApplication(application);
            setLogo(application.id == SUMRISE_APP_ID ? sumrise_logo : admisol_logo);
        }

        const fetchClient = async (requestId) => {
            const getClientInfo = await axios.get(`${GET_CLIENT}?request_id=${requestId}`);
            const client = getClientInfo.data;
            setClient(client);
            window.client = client;
        }

        fetchApp(requestId);
        fetchClient(requestId);
    }, []);

    return (
     <Container component="main" maxWidth="xs">
        <Box
            sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            }}
        >
            <img alt="Company Logo" src={logo} style={{maxWidth: '100%', marginBottom: '40px'}}></img>
            <Typography component="h1" variant="h5">
            Sign in
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoFocus
                autoComplete='username'
                type="text" 
                onChange={(e) => setUser(e.target.value)}
                value={user}
                />
                <TextField
                margin="normal"
                required
                fullWidth
                autoComplete="current-password" 
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                />
                <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                >
                Sign In
                </Button>
            </Box>
        </Box>
        { errorMess ?  (<Alert severity="error">{errorMess}</Alert>) : (<></>)}
       
      </Container>)
}
 
export default Login;